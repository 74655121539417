// importing and setting up Font Awesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';

import {
    faTwitter as fabTwitter,
    faLinkedinIn as fabLinkedinIn,
    faFacebookF as fabFacebookF,
    faInstagram as fabInstagram
} from '@fortawesome/free-brands-svg-icons'

// load font-awesome libraries
library.add(
    fabLinkedinIn,
    fabTwitter,
    fabFacebookF,
    fabInstagram,
);

dom.watch()