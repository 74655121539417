declare global {
    interface Window {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        Splide: any;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        Alpine: any;
    }
}

// import css
import '~/css/app.css'
import './assets/icons'
// only core styles
import '@splidejs/splide/css/core';

import Alpine from 'alpinejs'
import Splide from 'alpine-splide'
import persist from '@alpinejs/persist'
window.Alpine = Alpine
Alpine.data('Splide', Splide)
Alpine.plugin(persist)
Alpine.store('overlay', false)
Alpine.start()

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR active')
    })
}